.home{
    background-image: url('../assets/background.jpg');
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.home p{
    font-size: 30px;
    margin: 0;
    font-weight: bold;
    color: white;
}