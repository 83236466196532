body {
  overflow: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2;
  /* border-radius: 5px; */
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #2976d2;
  } */

#drawer div {
  direction: rtl;
  justify-content: flex-start;
}

#drawer ul {
  direction: ltr;
}
